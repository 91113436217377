import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Minting from "./Pages/Minting";

const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./components/Header/index"));
const Footer = lazy(() => import("./components/Footer/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {
  return (
    <>
      <Router>
      <Suspense fallback={null}>
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
          <Route path="/minting" render={() => <Minting />} />
          <Route path="/" render={() => (
            <>
              <Header />
              <Home />
              <Footer />
            </>
            
          )} />
        </Switch>
      </Suspense>
    </Router>
    </>
  );
}

export default App;
